// Pages
import PageHome from "./Pages/PageHome/PageHome";

// Global Modules
import Header from "./GlobalModules/Header/Header";
import Footer from "./GlobalModules/Footer/Footer";
import Site from "./GlobalModules/Site/Site";

// Modules

// Components
import Link from "./Components/Link/Link";
import Text from "./Components/Text/Text";

// Not found
import ComponentNotFound from "./ComponentNotFound";

// Modules
const ComponentList: any = {
  // Pages
  page_home: PageHome,

  // Global Modules
  header: Header,
  footer: Footer,
  site: Site,

  // Modules

  // Components
  link: Link,
  text: Text,
};

const Components = (type: string) => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
