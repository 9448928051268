import React from "react";
import SbEditable from "storyblok-react";
import { Box, Flex } from "reflexbox";
import { useRecoilState } from "recoil";
import { Container, Row, Column } from "src/components/UI/Grid/Grid";
import { activeMenuState } from "src/recoil/atoms";
import Link from "src/storyblok/Components/Link/Link";
import LinkComponent from "src/components/LinkComponent/LinkComponent";

import { Props } from "./types";
import * as styles from "./Header.module.scss";

const Header = ({ blok, fullSlug }: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

  return (
    <SbEditable content={blok}>
      <Container as="header" className={styles.header} paddingTop={[15]}>
        <Row justifyContent="center">
          <Column width={[1, null, null, 4 / 12]}></Column>
          <Column width={[1, null, null, 2 / 12]}>
            <Flex width={1} justifyContent="center">
              <LinkComponent to="/" title="Oddwood">
                <Box className={styles.logotype}>Logo</Box>
              </LinkComponent>
            </Flex>
          </Column>
          <Column width={[1, null, null, 4 / 12]}>
            <Flex
              as="nav"
              width={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              className={styles.navigation}
              height="100%"
              paddingBottom={1}
            ></Flex>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  );
};

export default Header;
