import React from "react";
import { Box } from "reflexbox";

import Artwork from "src/components/Artwork/Artwork";

import * as styles from "./Site.module.scss";
import { Props } from "./types";

const Site = ({ blok }: Props): JSX.Element => {
  const { background, background_mobile } = blok || {};
  return (
    <Box className={styles.wrapper}>
      <Artwork
        background={
          background && background.filename ? background.filename : null
        }
        backgroundMobile={
          background_mobile && background_mobile.filename
            ? background_mobile.filename
            : null
        }
      />
    </Box>
  );
};

export default Site;
