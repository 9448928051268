import React from "react";
import SbEditable from "storyblok-react";
import SEO from "src/components/SEO/SEO";
import { getSeo } from "src/helpers/getSeo";
import Components from "src/storyblok/Components";
import * as styles from "./PageHome.module.scss";
import { Flex, Box } from "reflexbox";
import { Props } from "./types";
import { Container } from "src/components/UI/Grid/Grid";
import Markdown from "src/components/Markdown/Markdown";
import Logotype from "src/components/Logotype/Logotype";

const PageHome = ({ blok }: Props): JSX.Element => {
  const { title, logotype } = blok;

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <Flex
          width={1}
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginBottom={[4, null, null, null, 6, 8]}>
            <Logotype logotype={logotype} />
          </Box>
          <Box>
            {title && <Markdown className={styles.title}>{title}</Markdown>}
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  );
};

export default PageHome;
