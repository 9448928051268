import React from "react";
import { Box } from "reflexbox";

import Image from "src/components/UI/Image/Image";
import logo from "src/images/daze-days-logo.png";
import logoArtwork from "src/images/daze-days-logo-artwork.png";

import * as styles from "./Logotype.module.scss";
import { Props } from "./types";

import { useMousePosition } from "src/hooks/useMousePosition";
import { useWindowSize } from "src/hooks/useWindowSize";

const Logotype = ({ logotype }: Props): JSX.Element => {
  const { x, y } = useMousePosition();
  const { width, height } = useWindowSize();

  const hasMovedCursor = typeof x === "number" && typeof y === "number";

  const centerX = width ? width / 2 : 0;
  const centerY = height ? height / 2 : 0;

  const logoX = hasMovedCursor && x ? (x / centerX - 1) * 10 : 0;
  const logoY = hasMovedCursor && y ? (y / centerY - 1) * 10 : 0;
  const logoStyles = {
    transform: `translate(${logoX}px, ${logoY}px)`,
  };

  const artworkX = hasMovedCursor && x ? (x / centerX - 1) * -10 : 0;
  const artworkY = hasMovedCursor && y ? (y / centerY - 1) * -10 : 0;
  const artworkStyles = {
    transform: `translate(${artworkX}px, ${artworkY}px)`,
  };
  return (
    <Box className={styles.wrapper}>
      {logotype && logotype.filename ? (
        <Image
          className={styles.logo}
          src={logotype.filename}
          alt={"Logotype"}
          size="33vw"
        />
      ) : (
        <React.Fragment>
          <Box
            as="img"
            src={logo}
            alt="Logo"
            style={logoStyles}
            className={styles.logo}
          />
          <Box
            as="img"
            src={logoArtwork}
            alt="Artwork"
            style={artworkStyles}
            className={styles.artwork}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default Logotype;
